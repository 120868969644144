/* eslint-disable max-len */

//#region IMPORT

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_ECONOMICVALUE_TYPE, ENUM_FINANCIALREPORT_INSURANCETYPE, ENUM_FINANCIALREPORT_PERIODTYPE, ENUM_HEADER_TYPE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertToBroadcastURL } from "src/app/functions/pipes/hyperlink.pipe";
import { RegexFunction } from "src/app/functions/regex.function";
import { TableModel } from "src/app/models/bases/table.model";
import { ContentModel } from "src/app/models/content.model";
import { FinancialReportModel } from "src/app/models/financialreport.model";
import { HeaderModel } from "src/app/models/header.model";
import { ResponseModel } from "src/app/models/response.model";
import { FinancialReportService } from "src/app/services/financialreport.service";
import { HeaderService } from "src/app/services/header.service";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { TableControlIncludeMiniComponent } from "../includes/tablecontrolmini.include/tablecontrolmini.include.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-economicvalue",
		templateUrl: "./economicvalue.component.html",
		styleUrls: ["./economicvalue.component.sass"],
	}
)

//#endregion


//#region CLASS

export class EconomicValueComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	@ViewChild(TableControlIncludeMiniComponent) private _componentTableControlMiniIncludeYearly!: TableControlIncludeMiniComponent;
	@ViewChild(TableControlIncludeMiniComponent) private _componentTableControlMiniIncludeMonthly!: TableControlIncludeMiniComponent;

	public _modelHeader: HeaderModel;
	public _modelFinancialReport: FinancialReportModel;
	public _modelTable: TableModel;

	public _enumStringConstant = StringConstant;
	public _enumEconomicValueType = ENUM_ECONOMICVALUE_TYPE;
	public _enumFinancialReportInsuranceType = ENUM_FINANCIALREPORT_INSURANCETYPE;

	public _stringInputValue: string;

	public _numberConstant = NumberConstant;
	public _numberAnnualFinancialValueTypeActive: number;
	public _numberQuarterlyReportsValueTypeActive: number;

	public _modelContentTemporary: Array<ContentModel>;
	public _modelFinancialReportSearch: Array<FinancialReportModel>;
	public _arrayModelAnnualKonvensionalFinancial: Array<ContentModel>;
	public _arrayModelQuarterlyReports: Array<ContentModel>;
	public _arrayModelQuarterlyReportsSyariah: Array<ContentModel>;
	public _arrayPageSizeAnnualKonvensionalFinancial: Array<number>;
	public _arrayPageSizeQuarterlyReports: Array<number>;
	public _arrayPageSizeQuarterlyReportsSyariah: Array<number>;
	public _arrayModelFinancialReportTableYearly: Array<FinancialReportModel>;
	public _arrayModelFinancialReportTableMonthly: Array<FinancialReportModel>;
	public _arrayModelFinancialReportYearly: Array<FinancialReportModel>;
	public _arrayModelFinancialReportMonthlyConventional: Array<FinancialReportModel>;
	public _arrayModelFinancialReportMonthlySharia: Array<FinancialReportModel>;

	public _numberPageSizeAnnualKonvensionalFinancial: number;
	public _numberIndexAnnualKonvensionalFinancial: number;
	public _numberPageSizeQuarterlyReports: number;
	public _numberIndexQuarterlyReports: number;
	public _numberPageSizeQuarterlyReportsSyariah: number;
	public _numberIndexQuarterlyReportsSyariah: number;

	public _pipeConvertToBroadcastURL = new ConvertToBroadcastURL();

	public _functionRegex: RegexFunction;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router, private _serviceHeader: HeaderService, private _serviceFinancialReport: FinancialReportService)
	{
		super(serviceSession, router);

		this._modelHeader = new HeaderModel();
		this._modelFinancialReport = new FinancialReportModel();
		this._modelTable = new TableModel();

		this._modelContentTemporary = [];
		this._modelFinancialReportSearch = [];
		this._arrayModelAnnualKonvensionalFinancial =
		[
			{
				Name: "BRINS AR - Annual Report",
				Description: "2023",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/BRINS AR 2023 - Annual Report.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2023",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2023.pdf"
			},
			{
				Name: "Sustainability Report",
				Description: "2023",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/SR_2023_PT_BRI_Asuransi_Indonesia.pdf"
			},
			{
				Name: "BRINS AR - Annual Report",
				Description: "2022",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/BRINS AR 2022 - Annual Report.pdf"
			},
			{
				Name: "Sustainability Report",
				Description: "2022",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/SR_2022_PT_BRI_Asuransi_Indonesia.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2022",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2022.pdf"
			},
			{
				Name: "BRINS AR - Annual Report",
				Description: "2021",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/BRINS AR 2021 - Annual Report.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2021",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2020-2021.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2020",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2019-2020.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2019",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2018-2019.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2018",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2017-2018.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2017",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2016-2017.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2016",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2015-2016.png"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2015",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2014-2015.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2014",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2013-2014.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2013",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2012-2013.pdf"
			},
			{
				Name: "Laporan Keuangan Konven-Syariah",
				Description: "2012",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/tahunan/Laporan Keuangan Konven-Syariah 2011-2012.png"
			},
		];
		this._arrayModelQuarterlyReports =
		[
			{
				Name: "Laporan Publikasi Bulan Agustus 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Agustus 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juli 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Juli 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juni 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Juni 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Mei 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Mei 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan April 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan April 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Maret 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Maret 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Februari 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Februari 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Januari 2024 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Januari 2024 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Desember 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Desember 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan November 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan November 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Oktober 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Oktober 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan September 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan September 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Agustus 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Agustus 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juli 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Juli 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juni 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Juni 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Mei 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan Mei 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan April 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/konvensional/Laporan Publikasi Bulan April 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2023 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2023 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2022 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2022 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2022 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2022 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2022 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2022 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2022 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2022 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2021 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2021 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2021 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2021 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2021 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2021 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2021 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2021 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2020 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2020 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2020 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2020 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2020 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2020 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2020 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2020 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2019 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2019 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2019 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2019 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2019 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2019 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2019 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2019 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2018 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2018 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2018 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan III 2018 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2018 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan II 2018 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2018 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan I 2018 Konvensional.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2017 Konvensional",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/konvensional/Laporan Publikasi Triwulan IV 2017 Konvensional.pdf"
			},
		];
		this._arrayModelQuarterlyReportsSyariah =
		[
			{
				Name: "Laporan Publikasi Bulan Agustus 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Agustus 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juli 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Juli 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juni 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Juni 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Mei 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Mei 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan April 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan April 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Maret 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Maret 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Februari 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Februari 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Januari 2024 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Januari 2024 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Desember 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Desember 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan November 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan November 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Oktober 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Oktober 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan September 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan September 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Agustus 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Agustus 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juli 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Juli 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Juni 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Juni 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan Mei 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan Mei 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Bulan April 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/bulanan/syariah/Laporan Publikasi Bulan April 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2023 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2023 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2022 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2022 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2022 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2022 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2022 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2022 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2022 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2022 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2021 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2021 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2021 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2021 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2021 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2021 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2021 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2021 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2020 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2020 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2020 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2020 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2020 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2020 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2020 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2020 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2019 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2019 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2019 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2019 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2019 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2019 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2019 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2019 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2018 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2018 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan III 2018 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan III 2018 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan II 2018 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan II 2018 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan I 2018 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan I 2018 Syariah.pdf"
			},
			{
				Name: "Laporan Publikasi Triwulan IV 2017 Syariah",
				Path: environment.URL_BROADCAST_API+"/Website/assets/pdf/laporankeuangan/triwulan/syariah/Laporan Publikasi Triwulan IV 2017 Syariah.pdf"
			},
		];
		this._arrayPageSizeAnnualKonvensionalFinancial = [];
		this._arrayPageSizeQuarterlyReports = [];
		this._arrayPageSizeQuarterlyReportsSyariah = [];
		this._arrayModelFinancialReportTableYearly = [];
		this._arrayModelFinancialReportTableMonthly = [];
		this._arrayModelFinancialReportYearly = [];
		this._arrayModelFinancialReportMonthlyConventional = [];
		this._arrayModelFinancialReportMonthlySharia = [];

		this._numberAnnualFinancialValueTypeActive = 0;
		this._numberQuarterlyReportsValueTypeActive = 0;
		this._numberIndexAnnualKonvensionalFinancial = 0;
		this._numberPageSizeAnnualKonvensionalFinancial = Math.ceil(this._arrayModelAnnualKonvensionalFinancial.length/this._numberConstant.NUMBER_COMPARE_VALUE_THREE);
		this._numberPageSizeQuarterlyReports = Math.ceil(this._arrayModelQuarterlyReports.length/this._numberConstant.NUMBER_COMPARE_VALUE_NINE);
		this._numberIndexQuarterlyReports = 0;
		this._numberIndexQuarterlyReportsSyariah = 0;
		this._numberPageSizeQuarterlyReportsSyariah = Math.ceil(this._arrayModelQuarterlyReportsSyariah.length/this._numberConstant.NUMBER_COMPARE_VALUE_NINE);

		this._stringInputValue = "";

		this._functionRegex = new RegexFunction();
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		this.callSelectHeaderByHeaderType(this);
		this.createPageSizeQuarterlyReports();
		this.createPageSizeQuarterlyReportsSyariah();
		this.createPageSizeAnnualKonvensionalFinancial();
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region WEB SERVICE

	public callSelectHeaderByHeaderType(componentCurrent: EconomicValueComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelHeader: HeaderModel = new HeaderModel();
		modelHeader.HeaderType = ENUM_HEADER_TYPE.EconomicValue;

		this._serviceHeader.selectHeaderByHeaderType
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelHeader.setModelFromString(modelResponse.Data);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}

				componentCurrent.callSelectFinancialReportForSearch(componentCurrent);
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {componentCurrent.callSelectHeaderByHeaderType(componentCurrent);});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, modelHeader);
	}

	public callSelectFinancialReportForSearch(componentCurrent: EconomicValueComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelFinancialReport: FinancialReportModel = new FinancialReportModel();

		this._serviceFinancialReport.selectFinancialReportForSearch
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelFinancialReport.setModelFromString(modelResponse.Data);
						console.log(componentCurrent._modelFinancialReport);

						if (componentCurrent._modelFinancialReport.listModelFinancialReportYearly)
						{
							componentCurrent._arrayModelFinancialReportYearly = [...componentCurrent._modelFinancialReport.listModelFinancialReportYearly];
						}
						if (componentCurrent._modelFinancialReport.listModelFinancialReportMonthlyConventional)
						{
							componentCurrent._arrayModelFinancialReportMonthlyConventional = [...componentCurrent._modelFinancialReport.listModelFinancialReportMonthlyConventional];
						}
						if (componentCurrent._modelFinancialReport.listModelFinancialReportMonthlySharia)
						{
							componentCurrent._arrayModelFinancialReportMonthlySharia = [...componentCurrent._modelFinancialReport.listModelFinancialReportMonthlySharia];
						}
						else
						{
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}

				componentCurrent.callSelectFinancialReportYearlyByAttributes(componentCurrent);
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {componentCurrent.callSelectFinancialReportForSearch(componentCurrent);});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, modelFinancialReport);
	}

	public callSelectFinancialReportYearlyByAttributes(componentCurrent: EconomicValueComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelFinancialReportRequest: FinancialReportModel = new FinancialReportModel();
		modelFinancialReportRequest.PeriodeType = ENUM_FINANCIALREPORT_PERIODTYPE.Yearly;

		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = 3;
		modelTable.Search = JSON.stringify(modelFinancialReportRequest);

		componentCurrent._serviceFinancialReport.selectFinancialReportByAttributes
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						modelTable.setModelFromString(modelResponse.Data);

						if (modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelFinancialReportTableYearly = [];
							componentCurrent._arrayModelFinancialReportTableYearly = JSON.parse(modelTable.Result);

							console.log(componentCurrent._arrayModelFinancialReportTableYearly);

							modelTable.Result = undefined;
							componentCurrent._componentTableControlMiniIncludeYearly.setButtonState();

							componentCurrent.callSelectFinancialReportMonthlyByAttributes(componentCurrent, ENUM_FINANCIALREPORT_INSURANCETYPE.Conventional);
						}
						else
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._arrayModelFinancialReportTableYearly = [];
							modelTable.Page = undefined;
							modelTable.PageTotal = undefined;
							componentCurrent._componentTableControlMiniIncludeYearly.setButtonState();
						});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectFinancialReportYearlyByAttributes(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	public callSelectFinancialReportMonthlyByAttributes(componentCurrent: EconomicValueComponent, enumInsuranceType: ENUM_FINANCIALREPORT_INSURANCETYPE): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelFinancialReportRequest: FinancialReportModel = new FinancialReportModel();
		modelFinancialReportRequest.PeriodeType = ENUM_FINANCIALREPORT_PERIODTYPE.Monthly;
		modelFinancialReportRequest.InsuranceType = enumInsuranceType;

		const modelTable: TableModel = new TableModel();
		modelTable.Search = JSON.stringify(modelFinancialReportRequest);

		componentCurrent._serviceFinancialReport.selectFinancialReportByAttributes
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						modelTable.setModelFromString(modelResponse.Data);

						if (modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelFinancialReportTableMonthly = [];
							componentCurrent._arrayModelFinancialReportTableMonthly = JSON.parse(modelTable.Result);
							console.log(componentCurrent._arrayModelFinancialReportTableMonthly);

							modelTable.Result = undefined;
							componentCurrent._componentTableControlMiniIncludeMonthly.setButtonState();
						}
						else
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._arrayModelFinancialReportTableYearly = [];
							modelTable.Page = undefined;
							modelTable.PageTotal = undefined;
							componentCurrent._componentTableControlMiniIncludeMonthly.setButtonState();
						});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectFinancialReportYearlyByAttributes(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	//#endregion


	//#region FUNCTION

	downloadFile(stringPath: string): void
	{
		stringPath = this._pipeConvertToBroadcastURL.transform(stringPath);
		window.open(stringPath, "_blank");
	}

	goToAnnualFinancial(enumEconomicValueTyoe: ENUM_ECONOMICVALUE_TYPE): void
	{
		this._numberAnnualFinancialValueTypeActive = enumEconomicValueTyoe;
	}

	goToQuarterlyReports(enumFinancialReportInsuranceType: ENUM_FINANCIALREPORT_INSURANCETYPE): void
	{
		if (enumFinancialReportInsuranceType === ENUM_FINANCIALREPORT_INSURANCETYPE.Conventional)
		{
			this.callSelectFinancialReportMonthlyByAttributes(this, enumFinancialReportInsuranceType);
			this._numberQuarterlyReportsValueTypeActive = enumFinancialReportInsuranceType;
		}
		else if (enumFinancialReportInsuranceType === ENUM_FINANCIALREPORT_INSURANCETYPE.Sharia)
		{
			this.callSelectFinancialReportMonthlyByAttributes(this, enumFinancialReportInsuranceType);
			this._numberQuarterlyReportsValueTypeActive = enumFinancialReportInsuranceType;
		}
		else
		{
		}
	}

	trackAnnualFinancial(index, modelAnnualFinancial: ContentModel)
	{
		return modelAnnualFinancial ? modelAnnualFinancial.ID : undefined;
	}

	trackQuarterlyReports(index, modelQuarterlyReports: ContentModel)
	{
		return modelQuarterlyReports ? modelQuarterlyReports.ID : undefined;
	}

	//#region Annual Konvensional

	createPageSizeAnnualKonvensionalFinancial(): void
	{
		for (let numberPageSize = 0; numberPageSize < this._numberPageSizeAnnualKonvensionalFinancial; numberPageSize++)
		{
			this._arrayPageSizeAnnualKonvensionalFinancial.push(numberPageSize);
		}
	}

	paginateAnnualFinancialKonvensionalModel(numberPage: number): Array<ContentModel>
	{
		const numberPageSize = this._numberConstant.NUMBER_COMPARE_VALUE_THREE;
		return this._arrayModelAnnualKonvensionalFinancial.slice(numberPage * numberPageSize, numberPage * numberPageSize + numberPageSize);
	}

	showPaginateAnnualKonvensionalFinancial(numberPaginate): void
	{
		this._numberIndexAnnualKonvensionalFinancial = numberPaginate;
	}

	//#endregion


	//#region QuarterlyReports Konvensional

	createPageSizeQuarterlyReports(): void
	{
		for (let numberPageSize = 0; numberPageSize < this._numberPageSizeQuarterlyReports; numberPageSize++)
		{
			this._arrayPageSizeQuarterlyReports.push(numberPageSize);
		}
	}

	paginateQuarterlyReportsModel(numberPage: number): Array<ContentModel>
	{
		const numberPageSize = this._numberConstant.NUMBER_COMPARE_VALUE_NINE;
		return this._arrayModelQuarterlyReports.slice(numberPage * numberPageSize, numberPage * numberPageSize + numberPageSize);
	}

	showPaginateQuarterlyReports(numberPaginate): void
	{
		this._numberIndexQuarterlyReports = numberPaginate;
	}

	//#endregion


	//#region QuarterlyReports Syariah

	createPageSizeQuarterlyReportsSyariah(): void
	{
		for (let numberPageSize = 0; numberPageSize < this._numberPageSizeQuarterlyReportsSyariah; numberPageSize++)
		{
			this._arrayPageSizeQuarterlyReportsSyariah.push(numberPageSize);
		}
	}

	paginateQuarterlyReportsSyariahModel(numberPage: number): Array<ContentModel>
	{
		const numberPageSize = this._numberConstant.NUMBER_COMPARE_VALUE_NINE;
		return this._arrayModelQuarterlyReportsSyariah.slice(numberPage * numberPageSize, numberPage * numberPageSize + numberPageSize);
	}

	showPaginateQuarterlyReportsSyariah(numberPaginate): void
	{
		this._numberIndexQuarterlyReportsSyariah = numberPaginate;
	}

	searchByValue(): void
	{
		this._modelFinancialReportSearch = [];
		if(this._stringInputValue !== null && this._stringInputValue !== undefined && this._stringInputValue !== "")
		{
			let arrayModelFinancialReportSearchTemp: Array<FinancialReportModel> = this._arrayModelFinancialReportYearly.filter(o =>
				Object.keys(o).some(stringField => o[stringField].toString().toLowerCase().includes(this._stringInputValue.toLowerCase())));

			arrayModelFinancialReportSearchTemp.forEach(element =>
			{
				this._modelFinancialReportSearch.push(element);
			});

			arrayModelFinancialReportSearchTemp = this._arrayModelFinancialReportMonthlyConventional.filter(o =>
				Object.keys(o).some(stringField => o[stringField].toString().toLowerCase().includes(this._stringInputValue.toLowerCase())));

			arrayModelFinancialReportSearchTemp.forEach(element =>
			{
				this._modelFinancialReportSearch.push(element);
			});

			arrayModelFinancialReportSearchTemp = this._arrayModelFinancialReportMonthlySharia.filter(o =>
				Object.keys(o).some(stringField => o[stringField].toString().toLowerCase().includes(this._stringInputValue.toLowerCase())));

			arrayModelFinancialReportSearchTemp.forEach(element =>
			{
				this._modelFinancialReportSearch.push(element);
			});
		}

	}

	getImageFromBroadcastURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}

	//#endregion


	//#endregion


	//#region NAVIGATION

	goToDirectors(): void
	{
		this._router.navigate(["home", "about", "organizationandstructure", "directors"]);
	}

	goToCommissioners(): void
	{
		this._router.navigate(["home", "about", "organizationandstructure", "commissioner"]);
	}

	goToDivision(): void
	{
		this._router.navigate(["home", "about", "organizationandstructure", "division"]);
	}

	//#endregion


	//#region SETTER

	public setEventEmitterSelectYearly(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;
		this.callSelectFinancialReportYearlyByAttributes(this);
	}

	public setEventEmitterSelectMonthly(modelTableUpdate: TableModel, enumInsuranceType: ENUM_FINANCIALREPORT_INSURANCETYPE): void
	{
		this._modelTable = modelTableUpdate;
		this.callSelectFinancialReportMonthlyByAttributes(this, enumInsuranceType);
	}

	//#endregion

}

//#endregion
