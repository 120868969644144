//#region IMPORT

import { ENUM_BRINSUPDATE_PUBLISHEDSTATUS, ENUM_FINANCIALREPORT_INSURANCETYPE, ENUM_FINANCIALREPORT_PERIODTYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class FinancialReportModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for financial report model.
		Author: Dimas Dandy.
		Created on : Friday, 15 November 2024. 			Updated on :.
		Created by : Dimas Dandy.						Updated by :.
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;
	Title?: string;
	PeriodeType?: ENUM_FINANCIALREPORT_PERIODTYPE;
	PeriodeTypeName?: string;
	InsuranceType?: ENUM_FINANCIALREPORT_INSURANCETYPE;
	InsuranceTypeName?: string;
	PublishedStatus?: ENUM_BRINSUPDATE_PUBLISHEDSTATUS;
	PublishedStatusName?: string;

	Month?: string;
	Year?: string;
	IsEdited?: boolean;

	listModelFinancialReportGallery?: Array<ProductBaseGalleryModel>;
	listModelFinancialReportYearly?: Array<FinancialReportModel>;
	listModelFinancialReportMonthlyConventional?: Array<FinancialReportModel>;
	listModelFinancialReportMonthlySharia?: Array<FinancialReportModel>;

	/* Attribute - END */


	//#region VALIDATION

	validateToken(stringToken: string)
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Financial Report");

		if (stringToken === undefined || stringToken === null || stringToken === "")
		{
			modelResponse.MessageContent = "Token undefined.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setPublishedStatusNameName(): void
	{
		if (this.PublishedStatus === ENUM_BRINSUPDATE_PUBLISHEDSTATUS.ApprovedBySigner)
		{
			this.PublishedStatusName = StringConstant.STRING_APPROVAL_STATUS_APPROVEDBYSIGNER;
		}
		else if (this.PublishedStatus === ENUM_BRINSUPDATE_PUBLISHEDSTATUS.RejectedBySigner)
		{
			this.PublishedStatusName = StringConstant.STRING_APPROVAL_STATUS_REJECTEDBYSIGNER;
		}
		else if (this.PublishedStatus === ENUM_BRINSUPDATE_PUBLISHEDSTATUS.SubmittedByMaker)
		{
			this.PublishedStatusName = StringConstant.STRING_APPROVAL_STATUS_SUBMITTEDBYMAKER;
		}
		else
		{
			this.PublishedStatusName = StringConstant.STRING_CHARACTER_DASH;
		}
	}

	setInsuranceTypeName(): void
	{
		if (this.InsuranceType === ENUM_FINANCIALREPORT_INSURANCETYPE.Conventional)
		{
			this.InsuranceTypeName = StringConstant.STRING_INSURANCE_TYPE_CONVENSIONAL;
		}
		else if (this.InsuranceType === ENUM_FINANCIALREPORT_INSURANCETYPE.Sharia)
		{
			this.InsuranceTypeName = StringConstant.STRING_INSURANCE_TYPE_SHARIA;
		}
		else
		{
			this.PublishedStatusName = StringConstant.STRING_CHARACTER_DASH;
		}
	}

	setPeriodeTypeName(): void
	{
		if (this.PeriodeType === ENUM_FINANCIALREPORT_PERIODTYPE.Monthly)
		{
			this.PeriodeTypeName = StringConstant.STRING_PERIOD_TYPE_MONTHLY;
		}
		else if (this.PeriodeType === ENUM_FINANCIALREPORT_PERIODTYPE.Yearly)
		{
			this.PeriodeTypeName = StringConstant.STRING_PERIOD_TYPE_YEARLY;
		}
		else
		{
			this.PublishedStatusName = StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion
}

//#endregion