<div class="DivTableControl">
	<div class="DivTableSubControl">
		<label>Halaman  {{ _modelTableForm.Page }} dari {{ _modelTableForm.PageTotal }} dengan {{ _modelTableForm.RowPerPage }} baris</label>
	</div>

	<div class="DivTableSubControl">
		<label *ngIf="_modelTableForm.Page! > 1" (click)="goToFirst()">Pertama</label>
		<label>
			<span *ngIf="_modelTableForm.Page! > 1" (click)="goToPrevious()"> {{ _modelTableForm.Page! - 1 }} </span>
			<strong> {{ _modelTableForm.Page }} </strong>
			<span *ngIf="booleanNextPage()" (click)="goToNext()"> {{ _modelTableForm.Page! + 1 }} </span>
		</label>
		<label *ngIf="booleanNextPage()" (click)="goToLast()">Terakhir</label>
	</div>
</div>