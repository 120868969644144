//#region CONSTANT

export class StringConstant
{

	//#region LINK

	static STRING_REDIRECT_BRINSMOBILE_APPS: string = "https://onelink.to/r4d4y5";

	//#endregion


	//#region REGULUAR_EXPRESSION

	static STRING_REGULAREXPRESSION_PERSONNAME: string = "^[A-Za-z\\s]{1,}[\\.]{0," + "1}[A-Za-z\\s]{0,}$";
	static STRING_REGULAREXPRESSION_ALPHABET: string = "^[a-zA-Z]+$";
	static STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE: string = "^[a-zA-Z ]+$";
	static STRING_REGULAREXPRESSION_NUMERIC: string = "^[0-9]*$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERIC: string = "^[a-zA-Z0-9]+$";
	static STRING_REGULAREXPRESSION_EMAIL: string = "[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}\\@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+";
	static STRING_REGULAREXPRESSION_MOBILEPHONEWITHPLUS: string = "^[+]?[0-9]{10,15}$";
	static STRING_REGULAREXPRESSION_DATEISO8601: string = "[0-9]{4}-[0-9]{2}-[0-9]{1,2}";
	static STRING_REGULAREXPRESSION_CURRENCY: string = "[^0-9]";
	static STRING_REGULAREXPRESSION_SPACE: RegExp = /\s/g;
	static STRING_REGULAREXPRESSION_MINUS: string = "-";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTTAGEMOJI: string = "^\\S[a-zA-Z0-9.,/\\s]+$";
	static STRING_REGULAREXPRESSION_EMAILWITHOUTEMOJI: string = "^[a-za-zA-Z0-9._-]+@[a-za-zA-Z0-9.-]+\\.[a-za-zA-Z]{2,4}$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI: string = "^\\S[a-zA-Z0-9\\s]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICFORADDRESS: string = "^\\S[a-zA-Z0-9.,/\\s]+$";

	//#endregion


	//#region LANGUAGE_CULTURE

	static STRING_CULTURE_INDONESIAN: string = "id-ID";
	static STRING_CULTURE_ENGLISH: string = "en-US";

	static STRING_LANGUAGE_INDONESIAN: string = "ID";
	static STRING_LANGUAGE_ENGLISH: string = "EN";

	//#endregion


	//#region CHARACTER

	static STRING_CHARACTER_SEPARATOR_DATE_VIEW: string = "/";
	static STRING_CHARACTER_SEPARATOR_DATE_SERVER: string = "-";
	static STRING_CHARACTER_SEPARATOR_DATETIME_SERVER: string = "'T'";
	static STRING_CHARACTER_SEPARATOR_TIME_VIEW: string = ":";
	static STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW: string = "Z";
	static STRING_CHARACTER_SEPARATOR_DECIMAL: string = ";";
	static STRING_CHARACTER_SEPARATOR_THOUSAND: string = ".";
	static STRING_CHARACTER_DASH: string = " ";
	static STRING_CHARACTER_EMPTY: string = "-";

	static STRING_CHARACTER_SEPARATOR_FORMAT: string = ".";
	static STRING_CHARACTER_SEPARATOR_DIRECTORY: string = "/";
	static STRING_CHARACTER_SEPARATOR_FILENAME: string = "_";

	//#endregion


	//#region TAG

	static STRING_TAG_VERIFY_IDENTITYCARD: string = "PRODUCTASRI_";
	static STRING_TAG_VERIFY_SELFIEIDENTITYCARD: string = "PRODUCTOTO_";

	//#endregion


	//#region DATE

	static STRING_DATE_SERVER_DEFAULT: string = "0000" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00";
	static STRING_TIME_SERVER_DEFAULT: string = "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_DEFAULT: string = StringConstant.STRING_DATE_SERVER_DEFAULT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_DEFAULT;
	static NUMBER_TIME_SERVER_COMPONENT: number = 3;

	static STRING_DATE_SERVER_FORMAT: string = "yyyy-MM-dd";
	static STRING_TIME_SERVER_FORMAT: string = "HH:mm:ss";
	static STRING_DATETIME_SERVER_FORMAT: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_FORMATWITHOUTTIMEZONE: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT;

	static STRING_DATE_VIEW_FORMAT: string = "dd/MM/yyyy";
	static STRING_TIME_VIEW_FORMAT: string = "HH:mm:ss";
	static STRING_TIME_VIEW_FORMATSHORT: string = "HH:mm";
	static STRING_DATETIME_VIEW_FORMAT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMAT;
	static STRING_DATETIME_VIEW_FORMATSHORT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMATSHORT;
	static STRING_TIMEZONE_VIEW_FORMAT: string = "GMT+07:00";

	static STRING_CURRENCY_IDR_CODE: string = "IDR";
	static STRING_CURRENCY_IDR_PREFIX: string = "Rp. ";
	static STRING_CURRENCY_THOUSANDS: string = "K. ";
	static STRING_CURRENCY_KILO: string = " Km";
	static STRING_CURRENCY_KILOMETERSPERHOUR: string = " Km/h";

	static STRING_DATE_SHORT_FORMAT = "d-M-yyyy";
	static STRING_DATE_MEDIUM_FORMAT = "d MMMM y";
	static STRING_DATE_LONG_FORMAT = "EEEE, d MMMM y";
	static STRING_DATE_TO_TIME_24HOURS = "it-IT";
	static STRING_DATE_TO_TIME_12HOURS = "en-US";

	static STRING_TIME_SHORT_FORMAT = "HH:mm";
	static STRING_TIME_MEDIUM_FORMAT = "HH:mm:ss";
	static STRING_TIME_LONG_FORMAT = "HH:mm:ss z";

	static STRING_DATETIME_COMPLETE_FORMAT = "EEEE, d MMMM y | HH:mm:ss";

	//#endregion


	//#region KEY_VALUE

	static STRING_VALUE_YEAR_MINIMUM: string = "1900";
	static STRING_VALUE_APPLICATION_VERSION: string = "1.3.6";
	static STRING_VALUE_CLIENT_VERSION: string = "1.1:10";
	static STRING_VALUE_PACKAGE_ASRISILVER: string = "ASRI-01";
	static STRING_VALUE_PACKAGE_ASRIGOLD: string = "ASRI-02";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM: string = "ASRI-03";
	static STRING_VALUE_PACKAGE_OTOBRONZE: string = "OTO-01";
	static STRING_VALUE_PACKAGE_OTOSILVER: string = "OTO-02";
	static STRING_VALUE_PACKAGE_OTOGOLD: string = "OTO-03";
	static STRING_VALUE_PACKAGE_OTOPLATINUM: string = "OTO-04";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM: string = "PA-BM";
	static STRING_VALUE_PRODUCT_ASRI_SYARIAH: string = "0164";
	static STRING_VALUE_PRODUCT_OTO_SYARIAH: string = "0256";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT_SYARIAH: string = "1051";
	static STRING_VALUE_PACKAGE_ASRISILVER_SYARIAH: string = "ASRI-01-S";
	static STRING_VALUE_PACKAGE_ASRIGOLD_SYARIAH: string = "ASRI-02-S";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM_SYARIAH: string = "ASRI-03-S";
	static STRING_VALUE_PACKAGE_OTOBRONZE_SYARIAH: string = "OTO-01-S";
	static STRING_VALUE_PACKAGE_OTOSILVER_SYARIAH: string = "OTO-02-S";
	static STRING_VALUE_PACKAGE_OTOGOLD_SYARIAH: string = "OTO-03-S";
	static STRING_VALUE_PACKAGE_OTOPLATINUM_SYARIAH: string = "OTO-04-S";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM_SYARIAH: string = "PA-BM-S";

	static STRING_VALUE_FILENAME_CALCULATEPREMIUM: string = "calculatepremium";
	static STRING_VALUE_FILENAME_SPPA: string = "sppa";
	static STRING_VALUE_FILENAME_POLICYOUTSTANDING: string = "policyoutstanding";
	static STRING_VALUE_FILENAME_POLICYRENEWAL: string = "policyrenewal";

	static STRING_VALUE_PRINTJOB_PDFPREVIEW: string = "Anjani - PDF Preview";

	static STRING_VALUE_COUNTRYCODE_INDONESIA: string = "+62";
	static STRING_VALUE_COUNTRYCODE_VIETNAM: string = "+84";
	static STRING_VALUE_COUNTRYCODE_NEWJERSEY: string = "+856";
	static STRING_VALUE_COUNTRYCODE_SINGAPORE: string = "+65";
	static STRING_VALUE_COUNTRYCODE_MALAYSIA: string = "+60";
	static STRING_VALUE_COUNTRYCODE_BRUNEI: string = "+673";
	static STRING_VALUE_COUNTRYCODE_THAILAND: string = "+66";
	static STRING_VALUE_COUNTRYCODE_MYANMAR: string = "+95";
	static STRING_VALUE_COUNTRYCODE_PHILIPPINES: string = "+63";
	static STRING_VALUE_COUNTRYCODE_CAMBODIA: string = "+855";
	static STRING_VALUE_MOBILEPHONE_PREFIXCODE: string = "0";
	static STRING_VALUE_MOBILEPHONE_PREFIXCODE_INDONESIAN: string = "62";
	static STRING_VALUE_TELEPHONE_BRINS_PRIMARY: string = "(21)79170477";
	static STRING_VALUE_TELEPHONE_BRINS_SECONDARY: string = "(21)79170478";
	static STRING_VALUE_TELEPHONE_CALLCENTER: string = "14081";
	static STRING_VALUE_DAMAGETYPE_TOTALLOSSONLY = "DM02050007";
	static STRING_VALUE_DAMAGETYPE_CONSTRUCTIVETOTALLOSS = "DM02050005";

	static ARRAY_VALUE_COUNTRYCODE: Array<string> = [
		StringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA, StringConstant.STRING_VALUE_COUNTRYCODE_VIETNAM ,StringConstant.STRING_VALUE_COUNTRYCODE_NEWJERSEY, StringConstant.STRING_VALUE_COUNTRYCODE_SINGAPORE,
		StringConstant.STRING_VALUE_COUNTRYCODE_MALAYSIA, StringConstant.STRING_VALUE_COUNTRYCODE_BRUNEI, StringConstant.STRING_VALUE_COUNTRYCODE_THAILAND, StringConstant.STRING_VALUE_COUNTRYCODE_MYANMAR,
		StringConstant.STRING_VALUE_COUNTRYCODE_PHILIPPINES, StringConstant.STRING_VALUE_COUNTRYCODE_CAMBODIA];

	static ARRAY_VALUE_TOC_FIRE: Array<string> = [ "0101", "0102", "0103", "0104", "0105", "0106", "0107", "0108", "0109", "0110", "0111", "0112", "0113", "0114", "0115", "0116", "0117", "0118", "0119", "0120", "0121", "0122", "0151", "0152", "0153", "0154", "0155", "0156", "0157", "0158", "0159", "0160", "0161", "0162", "0163", "0164", "0165", "0166", "0167" ];
	static ARRAY_VALUE_TOC_ENGINEERING: Array<string> = [ "0801", "0802", "0803", "0804", "0805", "0806", "0807", "0808", "0809", "0851", "0852", "0853", "0854", "0855", "0856", "0857", "0858" ];
	static ARRAY_VALUE_TOC_MV: Array<string> = ["0201", "0202", "0203", "0205", "0206", "0208", "0209", "0251", "0252", "0253", "0255", "0256", "0805", "0855"];
	// eslint-disable-next-line max-len
	static ARRAY_VALUE_TOC_VARIA: Array<string> = [ "0253", "0301", "0302", "0303", "0304", "0305", "0306", "0307", "0309", "0310", "0311", "0312", "0313", "0314", "0315", "0316", "0351", "0352", "0353", "0354", "0355", "0356", "0357", "0358", "0359", "0360", "0361", "0362", "0401", "0402", "0403", "0404", "0451", "0452", "0501", "0502", "0503", "0551", "0601", "0701", "0702", "0711", "0712", "0752", "0901", "0902", "0903", "0904", "0905", "0906", "0907", "0908", "0909", "0910", "0911", "0912", "0913", "0914", "0951", "0952", "0953", "0956", "0957", "0958", "1001", "1002", "1003", "1004", "1005", "1006", "1007", "1008", "1009", "1010", "1011", "1012", "1013", "1014", "1015", "1016", "1017", "1051", "1052", "1053", "1054", "1210", "1211", "1212", "1213", "1214", "1215", "1216", "1217", "1251", "1252", "1253", "1254", "1255", "1256", "1257", "1258", "1260", "1301", "1351"];
	// eslint-disable-next-line max-len
	static ARRAY_VALUE_TOC_FINANSIALLINE: Array<string> = [ "1101", "1102", "1103", "1104", "1105", "1106", "1107", "1108", "1109", "1110", "1111", "1112", "1151", "1152", "1153", "1154", "1155", "1156", "1157", "1158", "1159", "1160", "1202", "1203", "1204", "1208", "1212", "1217", "1251", "1252", "1253", "1254", "1258", "1260", "1401", "1402", "1403", "1404", "1405", "1406", "1407", "1408", "1409", "1410", "1411", "1412", "1413", "1414", "1415", "1416", "1451", "1452"];

	static STRING_CHART_PIE_LEGENDPOSITION: string = "below";
	static STRING_CHART_BAR_LEGENDPOSITION: string = "right";
	static STRING_FIELD_EMAIL_BRINS: string = "@work.brins.co.id";
	static STRING_FIELD_EMAIL_TSI_BRINS: string = "tsi@work.brins.co.id";
	static STRING_FIELD_EMAIL_INFO_BRINS: string = "callcenter@work.brins.co.id";
	static STRING_FIELD_BRANCHCODE_HEADQUARTER: string = "115";
	static STRING_FIELD_BRANCHCODE_HEADOFFICE: string = "100";
	static STRING_FIELD_BRANCHNAME_HEADOFFICE: string = "KANTOR PUSAT";
	static STRING_FIELD_MODELVEHICLEBYVEHICLEBRAND: string = "ADI";
	static ARRAY_STRING_SUMINSURED_FOR_CLAIMTHIRDPARTY: Array<string> = ["D06", "P13", "T02"];
	static STRING_COB_MOTOVEHICLE: string = "02";

	static STRING_SUMINSURED_DRIVER: string = "D06";
	static STRING_SUMINSURED_PASSENGER: string = "P13";


	/* PRODUCT CODE - START */

	static STRING_VALUE_PRODUCT_ASRI: string = "0117";
	static STRING_VALUE_PRODUCT_ASRIYMONTHLY: string = "0101";
	static STRING_VALUE_PRODUCT_ASRIYMONTHLYCUSTOMTEROBMC: string = "0110";
	static STRING_VALUE_PRODUCT_ASRIYMONTHLYCUSTOMPSAGBI: string = "0111";
	static STRING_VALUE_PRODUCT_OTO: string = "0206";
	static STRING_VALUE_PRODUCT_OTOMONTHLYDAILY: string = "0201";
	static STRING_VALUE_PRODUCT_BICYCLE: string = "1216";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT: string = "1001";
	static STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE: string = "0119";
	static STRING_VALUE_PRODUCT_MYMOTORCYCLE: string = "0210";
	static STRING_VALUE_PRODUCT_MYHOUSE: string = "0114";

	/* PRODUCT CODE - END */


	/* COVERAGE CODE YEARLY - START */

	// ASRI
	static STRING_VALUE_PACKAGE_YEARLY_ASRISILVER: string = "ASRI-01-V3";
	static STRING_VALUE_PACKAGE_YEARLY_ASRIGOLD: string = "ASRI-02-V3";
	static STRING_VALUE_PACKAGE_YEARLY_ASRIPLATINUM: string = "ASRI-03-V3";

	// OTO
	static STRING_VALUE_PACKAGE_YEARLY_OTOBRONZE: string = "OTO-01-V3";
	static STRING_VALUE_PACKAGE_YEARLY_OTOSILVER: string = "OTO-02-V3";
	static STRING_VALUE_PACKAGE_YEARLY_OTOGOLD: string = "OTO-03-V3";
	static STRING_VALUE_PACKAGE_YEARLY_OTOGOLD_AUTHORIZEDWORKSHOP: string = "OTO-08-V3";
	static STRING_VALUE_PACKAGE_YEARLY_OTOPLATINUM: string = "OTO-04-V3";
	static STRING_VALUE_PACKAGE_YEARLY_OTOPLATINUM_AUTHORIZEDWORKSHOP: string = "OTO-09-V3";

	// DIRI
	static STRING_VALUE_PACKAGE_YEARLY_PERSONALACCIDENT: string = "PA-BM-V3";

	// BICYCLE
	static STRING_VALUE_PACKAGE_YEARLY_BICYCLESILVER: string = "1216-01-V3";
	static STRING_VALUE_PACKAGE_YEARLY_BICYCLEGOLD: string = "1216-02-V3";
	static STRING_VALUE_PACKAGE_YEARLY_BICYCLEPLATINUM: string = "1216-03-V3";

	// ASMIK RUMAHKU
	static STRING_VALUE_PACKAGE_YEARLY_MYHOUSE: string = "BM-RKU-01";

	// ASMIK KERUSAKAN TEMPAT USAHA
	static STRING_VALUE_PACKAGE_YEARLY_DAMAGEOFBUSINESSPLACE: string = "BM-KTU-01";

	// ASMIK MOTORKU
	static STRING_VALUE_PACKAGE_YEARLY_MYMOTORCYCLE: string = "OTO-07-V3";

	/* COVERAGE CODE YEARLY - END */


	/* COVERAGE CODE MONTHLY - START */

	// ASRI
	static STRING_VALUE_PACKAGE_ASRIMONTHLY_REQUEST: string = "ASRI-03-V3";
	static STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOM: string = "ASRI-04-V3";
	static STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOMEARTHQUAKE = "ASRI-05-V3";
	static STRING_VALUE_PACKAGE_ASRICUSTOM_COVERAGECUSTOMTERORISMSABOTAGE = "TERO-BM-V3";

	// OTO
	static STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_COMPREHENSIVE: string = "OTO-05-V3";
	static STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_COMPREHENSIVE_AUTHORIZEDWORKSHOP: string = "OTO-10-V3";
	static STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_TLO: string = "OTO-06-V3";
	static STRING_VALUE_PACKAGE_MONTHLY_OTODAILY: string = "PAYD-F-V3";
	static STRING_VALUE_PACKAGE_MONTHLY_OTODAILY_AUTHORIZEDWORKSHOP: string = "PAYD-G-V3";
	static STRING_VALUE_PACKAGE_OTODAILY_125: string = "125-V3";
	static STRING_VALUE_PACKAGE_OTODAILY_200: string = "200-V3";
	static STRING_VALUE_PACKAGE_OTODAILY_400: string = "400-V3";

	// DIRI
	static STRING_VALUE_PACKAGE_MONTHLY_PERSONALACCIDENT: string = "PA-BMC-V3";

	/* COVERAGE CODE MONTHLY - END */


	/* COVERAGE CODE DAILY - START */

	// OTO
	static STRING_VALUE_PACKAGE_DAILY_OTO: string = "PAYD-F-V3";

	// DIRI
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTDAILY: string = "PAYG-F-V3";

	/* COVERAGE CODE DAILY - END */

	//#endregion


	//#region IMAGE

	static STRING_FORMAT_IMAGE_JPG: string = "jpg";
	static STRING_FORMAT_COMPRESS_ZIP: string = "zip";
	static STRING_FORMAT_DOCUMENT_PDF: string = "pdf";
	static STRING_FORMAT_COMPRESS_RAR: string = "rar";
	static STRING_FORMAT_IMAGE_PNG: string = "png";
	static STRING_FORMAT_IMAGE_JPEG: string = "jpeg";
	static STRING_FORMAT_DOCUMENT_WORD: string = "doc";
	static STRING_FORMAT_DOCUMENT_WORDX: string = "docx";
	static STRING_FORMAT_DOCUMENT_EXCEL: string = "xls";
	static STRING_FORMAT_DOCUMENT_EXCELX: string = "xlsx";
	static STRING_FORMAT_DOCUMENT_CSV: string = "csv";
	static STRING_FORMAT_DOCUMENT_MP4: string = "mp4";

	static ARRAY_VALUE_FORMAT: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV];

	static ARRAY_VALUE_IMAGE: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG];

	//#endregion


	//#region CONVERTION

	static STRING_HASH_SHA256: string = "SHA-256";

	//#endregion


	//#region HTTP

	static STRING_HTTP_HEADER_AUTHORIZEDKEY: string = "AuthorizedKey";

	//#endregion


	//#region MODEL

	static STRING_MODULE_CLAIMPROPOSAL: string = "Claim Proposal.";
	static STRING_MODULE_TICKETNUMBER: string = "Ticket.";
	static STRING_MODULE_CLAIMPROPOSAL_ANO_NOTFOUND: string = "Policy general not found.";
	static STRING_MODULE_CLAIMPROPOSAL_INSERTDATA_SUCCESS: string = "Insert data success.";
	static STRING_MODULE_CLAIMPROPOSAL_VERIFYDATA_SUCCESS: string = "Verify data success.";
	static STRING_MODULE_CLAIMPROPOSAL_SELECT_HISTORYPOLICY: string = "History policy cannot be empty.";

	//#endregion


	//#region CLAIM PROPOSAL MESSAGE CONTENT

	static STRING_MESSAGECONTENT_CLAIMPROPOSAL_LOCATIONISBLOCKED = "Location is blocked.";

	//#endregion


	//#region ROUTING

	static STRING_ROUTING_KEY_TOKEN: string = "token";
	static STRING_ROUTING_KEY_INSERT: string = "insert";
	static STRING_ROUTING_KEY_DETAIL: string = "detail";
	static STRING_ROUTING_POLICYNUMBER: string = "policynumber";
	static STRING_ROUTING_CLAIMNUMBER: string = "claimnumber";
	static STRING_ROUTING_PAYMENTANO: string = "ano";
	static STRING_ROUTING_PUBLIC: string = "public";
	static STRING_ROUTING_TICKET: string = "ticket";
	static STRING_ROUTING_SIGNIN: string = "signin";

	//#endregion


	//#region LOCAL STORAGE

	static STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL: string = "HandshakeModel";
	static STRING_LOCALSTORAGE_KEY_USERSESSION: string = "UserSession";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALASRILIST: string = "TableModelForApprovalAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALOTOLIST: string = "TableModelForApprovalOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEASRILIST: string = "TableModelForPurchaseAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEOTOLIST: string = "TableModelForPurchaseOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEPERSONALACCIDENTLIST: string = "TableModelForPurchasePersonalAccidentList";
	static STRING_LOCALSTORAGE_KEY_CLAIMPROPOSALMODEL_FOR_TRACKING_CLAIM: string = "ClaimProposalModelForTrackingClaim";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGELIST: string = "TableModelForPackageList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGEOPTIONLIST: string = "TableModelForPackageOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORCHATREPORTLIST: string = "TableModelForChatReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORBANREPORTLIST: string = "TableModelForBanReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGDAILYLIST: string = "TableModelForAdvertisingDailyList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGCARDLIST: string = "TableModelForAdvertisingCardList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTLIST: string = "TableModelForAccountList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTOPTIONLIST: string = "TableModelForAccountOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERAPPROVALLIST: string = "TableModelForSeekerApprovalList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERPROFILEREPORTLIST: string = "TableModelForSeekerProfileReportList";

	//#endregion


	//#region CHECK URL

	static STRING_URL_PATH_INSERT: string = "insert";

	//#endregion


	//#region LENGTH

	static NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM: number = 8;
	static NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMAXIMUM: number = 9;

	//#endregion


	//#region IFRAME

	static STRING_IFRAME_EMBEDVIDEO_AUTOPLAY: string = "?autoplay=1";
	static STRING_IFRAME_EMBEDVIDEO_PAUSE: string = "?enablejsapi=1";

	//#endregion


	//#region VALIDATION

	static STRING_VALIDATION_TICKET_EMPTY_NAME: string = "Nama tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_EMAIL: string = "Email tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_INCORRECT_EMAIL: string = "Format Email tidak sesuai!";
	static STRING_VALIDATION_TICKET_EMPTY_PHONENUMBER: string = "Nomor telepon boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_TITLE: string = "Judul tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_TOPIC: string = "Topik tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_DESCRIPTION: string = "Deskripsi tidak boleh kosong!";

	static STRING_VALIDATION_TICKET_FULLY_FILLED: string = "Tiket sudah terpenuhi!";

	//#endregion


	//#region WORDING

	static STRING_CONTENT_VISSION_BRINS_EN: string = "The Most Trusted Partner for Reliable Protection Solutions";
	static STRING_CONTENT_VISSION_BRINS_ID: string = "(Mitra Terpercaya dan Andal untuk Solusi Perlindungan)";

	static STRING_CONTENT_MISSION_BRINS_1: string = "Melakukan kegiatan usaha perasuransian terbaik yang dapat dipercaya, responsif, dan adaptif untuk ketenangan hati semua nasabah.";
	static STRING_CONTENT_MISSION_BRINS_2: string = "Memberikan pelayanan yang prima dan melebihi harapan nasabah.";
	static STRING_CONTENT_MISSION_BRINS_3: string = "Memberikan keuntungan dan manfaat yang optimal kepada seluruh stakeholder melalui penerapan Good Corporate Governance (GCG) dan Risk Management yang efektif.";
	static STRING_CONTENT_MISSION_BRINS_4: string = "Berkomitmen terhadap penerapan Environmental, Social, and Governance (ESG) dalam menjalankan bisnis asuransi yang berkelanjutan.";

	//#endregion


	//#region CLAIM PROPOSAL TRACKING

	static STRING_TRACKING_CLAIM_DAMAGETYPE_TOTALLOSSONLY: string = "DM02050007";
	static STRING_TRACKING_CLAIM_DAMAGETYPE_CONSTRUCTIVETOTALLOSS: string = "DM02050005";

	static STRING_TRACKING_CLAIM_STATUS_SURVEY: string = "Survei";
	static STRING_TRACKING_CLAIM_STATUS_VERIFICATION: string = "Verifikasi";
	static STRING_TRACKING_CLAIM_STATUS_SPK_PUBLISHED: string = "SPK Terbit";
	static STRING_TRACKING_CLAIM_STATUS_VEHICLEIN: string = "Kendaraan Masuk";
	static STRING_TRACKING_CLAIM_STATUS_FINISH: string = "Selesai";
	static STRING_TRACKING_CLAIM_STATUS_REGISTRASION: string = "Registrasi";
	static STRING_TRACKING_CLAIM_STATUS_VEHICLEOUT: string = "Kendaraan Keluar";
	static STRING_TRACKING_CLAIM_STATUS_REPAIR_PROCESS: string = "Proses Perbaikan";
	static STRING_TRACKING_CLAIM_STATUS_CUSTOMER_SATISFACTION_SURVEY: string = "Survei Kepuasan Pelanggan";
	static STRING_TRACKING_CLAIM: string = "Lacak Klaim";

	static STRING_TRACKING_CLAIM_VALIDATION_NONLOGIN: string = "Mohon untuk dapat mengisikan 2 data di kolom yang tersedia";
	static STRING_TRACKING_CLAIM_VALIDATION_LOGIN: string = "Mohon untuk dapat mengisikan 1 data di kolom yang tersedia";
	static STRING_TRACKING_CLAIM_VALIDATION_POLICY_HOLDER_IDENTIFICATION_NUMBER: string = "Format NIK harus berisi 16 digit angka.";
	static STRING_TRACKING_CLAIM_VALIDATION_POLICY_HOLDER_IDENTIFICATION_NUMBER_FORMAT: string = "Format NIK tidak benar.";

	static STRING_TRACKING_CLAIM_EASYREPORT: string = "Kemudahan Pelaporan";
	static STRING_TRACKING_CLAIM_SPEED_OF_RESPONSE: string = "Kecepatan Respon";
	static STRING_TRACKING_CLAIM_CLAIM_SERVICE: string = "Layanan Klaim";
	static STRING_TRACKING_CLAIM_REPAIR_QUALITY: string = "Kualitas Perbaikan";
	static STRING_TRACKUNG_CLAIM_SERVICE_QUALITY: string = "Kualitas Layanan";
	static STRING_TRACKING_CLAIM_WORKSHOP_QUALITY: string = "Fasilitas Bengkel";
	static STRING_TRACKING_CLAIM_SPEED_OF_REPAIR: string = "Kecepatan Perbaikan";
	static STRING_TRACKING_CLAIM_EASY_REPORTING: string = "Kemudahan Lapor";
	static STRING_TRACKING_CLAIM_FAST_RESPONSE: string = "Kecepatan Respon Petugas";
	static STRING_TRACKING_CLAIM_SERVICE: string = "Layanan";
	static STRING_TRACKING_CLAIM_MORE: string = "Lainnya";

	//#endregion


	//#region APPROVAL USE CUSTOMER DATA

	static STRING_APPROVAL_USE_CUSTOMER_DATA_FIRST: string = "Bersedia dan mengizinkan data pribadi digunakan dalam pertukaran data dan informasi oleh PT. BRI Asuransi Indonesia, induk dan anak Perusahaan serta Pihak ketiga yang bekerjasama dengan PT. BRI Asuransi Indonesia dengan tujuan bisnis PT. BRI Asuransi Indonesia sesuai ketentuan perundang-undangan yang berlaku.";
	static STRING_APPROVAL_USE_CUSTOMER_DATA_SECOND: string = "Bersedia dan mengizinkan data pribadi digunakan dalam penawaran, promosi seluruh produk serta fasilitas layanan PT. BRI Asuransi Indonesia, induk dan anak Perusahaan serta Pihak ketiga yang bekerjasama dengan PT. BRI Asuransi Indonesia.";
	static STRING_APPROVAL_USE_CUSTOMER_DATA_NOTE: string = "Kami menghimbau untuk membaca seluruh informasi Persetujuan Penggunaan Data Nasabah.\nTerima kasih atas kepercayaan dan dukungan terus-menerus kepada PT. BRI Asuransi Indonesia.";

	//#endregion


	//#region APPROVAL STATUS

	static STRING_APPROVAL_STATUS_APPROVEDBYSIGNER: string = "Approved By Signer";
	static STRING_APPROVAL_STATUS_REJECTEDBYSIGNER: string = "Rejected By Signer";
	static STRING_APPROVAL_STATUS_SUBMITTEDBYMAKER: string = "Submitted By Maker";

	//#endregion


	//#region STRING MONTH ID

	static ARRAY_VALUE_STRING_MONTH_ID: Array<string> =
		[
			"Januari",
			"Februari",
			"Maret",
			"April",
			"Mei",
			"Juni",
			"Juli",
			"Agustus",
			"September",
			"Oktober",
			"November",
			"Desember",
		];

	//#endregion


	//#region INSURANCE TYPE

	static STRING_INSURANCE_TYPE_CONVENSIONAL: string = "Konvensional";
	static STRING_INSURANCE_TYPE_SHARIA: string = "Syariah";

	//#endregion


	//#region PERIODE TYPE

	static STRING_PERIOD_TYPE_MONTHLY: string = "Bulanan";
	static STRING_PERIOD_TYPE_YEARLY: string = "Tahunan";

	//#endregion
}

//#endregion
