//#region CLASS

export class NumberConstant
{
	//#region USER INTERFACE

	static NUMBER_PRODUCTASRI_GALLERY_SIZE: number = 5;
	static NUMBER_PRODUCTOTO_GALLERY_SIZE: number = 9;

	static NUMBER_VALUE_THEME_SECONDARY_DARK_RED: number = 242;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_GREEN: number = 111;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_BLUE: number = 33;

	static NUMBER_VALUE_THEME_CHART_STEP_RED: number = 20;
	static NUMBER_VALUE_THEME_CHART_STEP_GREEN: number = 15;
	static NUMBER_VALUE_THEME_CHART_STEP_BLUE: number = 3;

	static NUMBER_VALUE_DECIMAL_PREVIEW: number = 2;

	static NUMBER_EXCEL_COLUMN_POLICYHOLDERSTARTDATE: number = 0;
	static NUMBER_EXCEL_COLUMN_POLICYHOLDERENDDATE: number = 1;
	static NUMBER_EXCEL_COLUMN_INSUREDNAME: number = 2;
	static NUMBER_EXCEL_COLUMN_RISKLOCATIONREMARKS: number = 3;
	static NUMBER_EXCEL_COLUMN_BUILDINGPRICE: number = 4;
	static NUMBER_EXCEL_COLUMN_MARGIN: number = 5;
	static NUMBER_EXCEL_COLUMN_REFERENCENUMBER: number = 6;

	static NUMBER_VALUE_TEN_PERCENT: number = 0.1;


	static NUMBER_PIXEL_MINIMUM_SMARTPHONE: number = 490;
	static NUMBER_PIXEL_MINIMUM_TABLET: number = 800;

	//#endregion


	//#region COMPARE

	static NUMBER_COMPARE_VALUE_ZERO: number = 0;
	static NUMBER_COMPARE_VALUE_ONE: number = 1;
	static NUMBER_COMPARE_VALUE_TWO: number = 2;
	static NUMBER_COMPARE_VALUE_THREE: number = 3;
	static NUMBER_COMPARE_VALUE_FOUR: number = 4;
	static NUMBER_COMPARE_VALUE_FIVE: number = 5;
	static NUMBER_COMPARE_VALUE_SIX: number = 6;
	static NUMBER_COMPARE_VALUE_SEVEN: number = 7;
	static NUMBER_COMPARE_VALUE_EIGHT: number = 8;
	static NUMBER_COMPARE_VALUE_NINE: number = 9;
	static NUMBER_COMPARE_VALUE_TEN: number = 10;

	//#endregion


	//#region INTERVAL

	static NUMBER_INTERVAL_ONE_SECOND: number = 1000;
	static NUMBER_INTERVAL_TWO_SECONDS: number = 2000;
	static NUMBER_INTERVAL_THREE_SECONDS: number = 3000;
	static NUMBER_INTERVAL_FOUR_SECONDS: number = 4000;
	static NUMBER_INTERVAL_FIVE_SECONDS: number = 5000;
	static NUMBER_INTERVAL_EIGHT_SECONDS: number = 8000;
	static NUMBER_VALUE_ROWPERPAGE_FOR_SELECTBRANCH: number = 100;

	//#endregion
}

//#endregion