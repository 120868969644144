//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { FinancialReportModel } from "../models/financialreport.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class FinancialReportService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SERVICE

	selectFinancialReportByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_FINANCIALREPORT_SELECTFINANCIALREPORTBYATTRIBUTES);
	}

	selectFinancialReportForSearch(interfaceGeneralService: GeneralServiceInterface, modelFinancialReport: FinancialReportModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelFinancialReport), WebAddressConstant.STRING_URL_FINANCIALREPORT_SELECTFINANCIALREPORTFORSEARCH);
	}

	//#endregion

}

//#endregion
