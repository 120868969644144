//#region IMPORT

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TableModel } from "src/app/models/bases/table.model";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-tablecontrolincludemini",
		templateUrl: "./tablecontrolmini.include.component.html",
		styleUrls: ["./tablecontrolmini.include.component.sass"]
	}
)

//#endregion


//#region CLASS

export class TableControlIncludeMiniComponent implements OnInit
{
	//#region DECLARATION

	@Input() public _modelTableForm: TableModel;
	@Output() public _eventEmitterSelect: EventEmitter<TableModel> = new EventEmitter<TableModel>();

	@Input() public _booleanButtonFirst: boolean;
	@Input() public _booleanButtonPrevious: boolean;
	@Input() public _booleanButtonNext: boolean;
	@Input() public _booleanButtonLast: boolean;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		this._modelTableForm = new TableModel();

		this._booleanButtonFirst = true;
		this._booleanButtonPrevious = true;
		this._booleanButtonNext = true;
		this._booleanButtonLast = true;
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{

	}

	//#endregion


	//#region SETTER

	private eventEmitterSelect(modelTable: TableModel): void
	{
		this._eventEmitterSelect.emit(modelTable);
	}

	public setButtonState(): void
	{
		if (this._modelTableForm.Page == null || this._modelTableForm.Page === undefined || this._modelTableForm.Page === 0 || this._modelTableForm.PageTotal == null || this._modelTableForm.PageTotal === undefined || this._modelTableForm.PageTotal === 0)
		{
			this._booleanButtonNext = true;
			this._booleanButtonLast = true;
			this._booleanButtonFirst = true;
			this._booleanButtonPrevious = true;
		}
		else if (this._modelTableForm.Page === 1)
		{
			this._booleanButtonFirst = true;
			this._booleanButtonPrevious = true;

			if (this._modelTableForm.PageTotal === this._modelTableForm.Page + 1)
			{
				this._booleanButtonNext = false;
				this._booleanButtonLast = true;
			}
			else if (this._modelTableForm.PageTotal > this._modelTableForm.Page + 1)
			{
				this._booleanButtonNext = false;
				this._booleanButtonLast = false;
			}
			else
			{
				this._booleanButtonNext = true;
				this._booleanButtonLast = true;
			}
		}
		else if (this._modelTableForm.Page === 2)
		{
			this._booleanButtonFirst = true;
			this._booleanButtonPrevious = false;

			if (this._modelTableForm.PageTotal === this._modelTableForm.Page + 1)
			{
				this._booleanButtonNext = false;
				this._booleanButtonLast = true;
			}
			else if (this._modelTableForm.PageTotal > this._modelTableForm.Page + 1)
			{
				this._booleanButtonNext = false;
				this._booleanButtonLast = false;
			}
			else
			{
				this._booleanButtonNext = true;
				this._booleanButtonLast = true;
			}
		}
		else if (this._modelTableForm.Page === this._modelTableForm.PageTotal - 1)
		{
			this._booleanButtonNext = false;
			this._booleanButtonLast = true;

			if (this._modelTableForm.Page > 1)
			{
				this._booleanButtonFirst = false;
				this._booleanButtonPrevious = false;
			}
			else if (this._modelTableForm.Page === 1)
			{
				this._booleanButtonFirst = true;
				this._booleanButtonPrevious = false;
			}
			else
			{
				this._booleanButtonFirst = true;
				this._booleanButtonPrevious = true;
			}
		}
		else if (this._modelTableForm.Page === this._modelTableForm.PageTotal)
		{
			this._booleanButtonNext = true;
			this._booleanButtonLast = true;

			if (this._modelTableForm.Page > 1)
			{
				this._booleanButtonFirst = false;
				this._booleanButtonPrevious = false;
			}
			else if (this._modelTableForm.Page === 1)
			{
				this._booleanButtonFirst = true;
				this._booleanButtonPrevious = false;
			}
			else
			{
				this._booleanButtonFirst = true;
				this._booleanButtonPrevious = true;
			}
		}
		else
		{
			this._booleanButtonNext = false;
			this._booleanButtonLast = false;
			this._booleanButtonFirst = false;
			this._booleanButtonPrevious = false;
		}
	}

	//#endregion


	//#region TABLE

	public goToFirst(): void
	{
		this._modelTableForm.Page = 1;
		this.eventEmitterSelect(this._modelTableForm);
	}

	public goToPrevious(): void
	{
		if (this._modelTableForm.Page != null && this._modelTableForm.Page !== undefined && this._modelTableForm.Page !== 0)
		{
			this._modelTableForm.Page -= 1;
			this.eventEmitterSelect(this._modelTableForm);
		}
		else
		{

		}
	}

	public goToNext(): void
	{
		if (this._modelTableForm.Page != null && this._modelTableForm.Page !== undefined && this._modelTableForm.Page !== 0)
		{
			this._modelTableForm.Page += 1;
			this.eventEmitterSelect(this._modelTableForm);
		}
		else
		{

		}
	}

	public goToLast(): void
	{
		this._modelTableForm.Page = this._modelTableForm.PageTotal;
		this.eventEmitterSelect(this._modelTableForm);
	}

	public listenRowPerPage(): void
	{
		if (this._modelTableForm.PageTotal != null && this._modelTableForm.PageTotal !== undefined && this._modelTableForm.PageTotal !== 0)
		{
			if (this._modelTableForm.PageTotal > 0)
			{
				this._modelTableForm.Page = 1;
				this.eventEmitterSelect(this._modelTableForm);
			}
			else
			{

			}
		}
		else
		{

		}
	}

	public booleanNextPage(): boolean
	{
		if(this._modelTableForm.Page == null || this._modelTableForm.Page === undefined || this._modelTableForm.PageTotal == null || this._modelTableForm.PageTotal === undefined)
		{
			return false;
		}
		else if(this._modelTableForm.Page < this._modelTableForm.PageTotal)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	//#endregion
}

//#endregion